import { CohortAssignmentSubject } from "@generated/graphql";
import { assertUnreachable } from "@utils/types";
import { IconType } from "components/shared/Icon";
import { CalendarDisplayMode } from "./types";

export const getDisplayRules = (mode: CalendarDisplayMode) => {
  switch (mode) {
    case CalendarDisplayMode.Responsive:
      return {
        mobileNav: "grid lg:hidden",
        desktopNav: "hidden lg:grid",
        verticalLines: "hidden lg:grid",
        orderedList: "grid-cols-1 lg:grid-cols-7",
        event: "lg:flex col-span-full lg:col-span-1 col-start-1",
      };
    case CalendarDisplayMode.AlwaysMobile:
      return {
        mobileNav: "grid",
        desktopNav: "hidden",
        verticalLines: "hidden",
        orderedList: "grid-cols-1 *:lg:col-start-1",
        event: "col-span-full",
      };
    default:
      assertUnreachable(mode);
  }
};

export const getCharmIcon = (
  cohortSubject: CohortAssignmentSubject
): IconType => {
  switch (cohortSubject) {
    case CohortAssignmentSubject.Math:
      return "math";
    case CohortAssignmentSubject.Ela:
      return "ela";
    case CohortAssignmentSubject.General:
      return "general";
    case CohortAssignmentSubject.Science:
      return "science";
    case CohortAssignmentSubject.SocialStudies:
      return "socialStudies";
    case CohortAssignmentSubject.Eld:
      return "eld";
    case CohortAssignmentSubject.Psat:
      return "psat";
    case CohortAssignmentSubject.Sat:
      return "sat";
    case CohortAssignmentSubject.Act:
      return "act";
    default:
      assertUnreachable(cohortSubject, "cohortSubject");
  }
};
